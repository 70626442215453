$(document).ready(function () {
    setCheckedMarkFromWidget()
    setCheckedMarkForEditForm()
    setCheckedRatingFilter()
    $.ajax({
        type: 'POST',
        url: location.origin + '/ajax_snippet.php?reviewsWidget',
        success: function (data) {
            try {
                let response = JSON.parse(data);

                if (response !== '') {
                    if (response['sticky'] !== '' && $(window).width() > 768) {
                        document.querySelector('main').insertAdjacentHTML('beforeend', response['sticky'])
                        hideStickySliderOnScroll()
                    }

                    if (document.querySelector('.contact-us-reviews-widget') != null) {
                        document.querySelector('.contact-us-reviews-widget').insertAdjacentHTML('afterbegin', response['footer'])
                    }
                    document.querySelector('.footer-widget-wrap').insertAdjacentHTML('afterbegin', response['footer'])
                    initWidgetsEvent()
                }
            } catch (error) {
                return false;
            }
        }
    });
});

/**
 * инициализация ивентов для виджета
 */
function initWidgetsEvent() {
    let starsRowsSelector = $('.sticky-widget .rating_star, .footer-widget .rating_star');

    setStarRating()
    $('#closeStickyWidget').on('click', function () {
        $('.sticky-widget').remove()
        setCookie('hideStickyWidget', '1', 1)
    })

    starsRowsSelector.on('mouseover', function () {
        let element = this.querySelectorAll('.star-color');
        if (element !== null) {
            element.forEach((el) => {
                el.classList.remove('star-color');
            })
        }
    })

    starsRowsSelector.on('mouseleave', function () {
        setStarRating()
    })

    $('.star-reviews').on('click', function (event) {
        event.preventDefault();
        let link = $(this).closest('.review-link').attr('href')
        let rating = $(this).attr('data-rating')
        $('.rating_star').attr('data-rating', rating)
        window.location.href = `${link}?rating=${rating}`;
    })
}

/**
 * скрытие приклееного виджета при скроле к футеру
 */
function hideStickySliderOnScroll() {
    $(window).on('scroll',function () {
        if ($(window).scrollTop() + $(window).height() >= $(document).height() - 150) {
            $('.sticky-widget').hide();
        } else {
            $('.sticky-widget').show();
        }
    });
    $(window).trigger('scroll');
}

/**
 * добавление активного класса звездочкам взависимости от среденей оценки
 */
function setStarRating() {
    let rating = Math.floor($('.rating_star').attr('data-rating'));

    if ($('.sticky-widget .rating_star') !== null) {
        $('.sticky-widget .rating_star .star-reviews').each((index, item) => {
            if (index >= 5 - rating) {
                $(item).addClass('star-color')
            }
        })
    }

    $('.footer-widget .rating_star .star-reviews').each((index, item) => {
        if (index >= 5 - rating) {
            $(item).addClass('star-color')
        }
    })
}

/**
 * выбор оценки отзыва на странице с отзывами взависимости от той, на которую нажал пользователь в виджете
 */
function setCheckedMarkFromWidget() {
    if ($('.review-mark') !== null) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let rating = urlParams.get("rating");

        if (rating !== null) {
            $(`input[name="general_mark"][value="${rating}"]`).prop("checked", true)
        }
    }
}

/**
 * выбор оценок отзыва на странице с отзывами в форме редактирования
 */
function setCheckedMarkForEditForm() {
    if ($('#generalMark') !== null) {
        let generalRating = $('#generalMark').attr('data-rating');
        let salesPersonRating = $('#salesPersonMark').attr('data-rating');
        let websiteRating = $('#websiteMark').attr('data-rating');

        if (generalRating !== null) {
            $(`input[name="general_mark"][value="${generalRating}"]`).prop("checked", true)
        }
        if (salesPersonRating !== null) {
            $(`input[name="sales_person_mark"][value="${salesPersonRating}"]`).prop("checked", true)
        }
        if (websiteRating !== null) {
            $(`input[name="website_mark"][value="${websiteRating}"]`).prop("checked", true)
        }
    }
}

/**
 * выбор галочки в фильтрах по оценке
 */
function setCheckedRatingFilter() {
    if ($('.rating-label') !== null) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let rating = urlParams.get("ratingStatistic");

        if (rating !== null) {
            $(`input.ratingFilter[data-value="${rating}"]`).prop("checked", true)
        }
    }
}

/**
 * добавление куки
 */
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}